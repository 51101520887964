<template lang="pug">
  Auth(@loginFailed="onFailedAuthentication")
    Header(ref="header")
    ModalWindow(
      @closeModal="closeModalWindow"
      modalContentId="setTrainer"
      :showModal="showModalWindow")
    div.wrap-trainer
      ModuleTrainer(
        ref="moduleTrainer"
        @openSetTrainer="openSetTrainer")

</template>

<style lang="scss" scoped>
.wrap-trainer {
  width: 100%;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/auth'
import Header from '@/components/shared/Header.vue'
import ModuleTrainer from '@/components/module/ModuleTrainer.vue'
import ModalWindow from '@/components/shared/Modal.vue'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    Header,
    ModuleTrainer,
    ModalWindow
  },
  data () {
    return {
      showModalWindow: false,
      modalWindowContentId: ""
    }
  },
  watch: {
    '$route': function (to, from) {
      // if (to.path !== from.path) {
      //   if (this.$route.params.recordId) {
      //     this.showModalPage = true
      //   } else {
      //     this.showModalPage = false
      //   }
      // }
    }
  },
  async created () {
    
  },
  methods: {
    ...mapActionsAuth(['signOut', 'getUserByUid']),
    logout () {
      this.signOut()
      setTimeout(() => {
        this.$router.push('/sign-in')
      }, 400)
    },
    onFailedAuthentication () {
      this.$router.push('/sign-in')
    },
    openSetTrainer () {
      this.modalWindowContentId = "setTrainer"
      this.showModalWindow = true
    },
    closeModalWindow () {
      this.showModalWindow = false
    },
    updateTrainerInfo () {
      this.$refs.moduleTrainer.updateTrainerInfo()
      this.$refs.header.updateUserInfo()
    }
  }
}
</script>

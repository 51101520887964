<template lang="pug">
  Auth(@loggedIn="onLoggedIn")
    div.wrap-header
      div.header-content
        div.left.f.fm
          //- div(@click="$router.push('/clients')").wrap-logo.f.fm
            img(src="/img/logo.png")
            span.logo-text DEPORTARE CLUB
        div.right.f.fm
          div(v-if="isLoggedIn").f.fm
            div.wrap-menu.f.fm.mr4
              v-menu(offset-y).mr2
                template(v-slot:activator="{ on }")
                  div(v-on="on").add-record-button.f.fm.px6.py4
                    v-icon(color="#fff") add
                div.menu-content.px12.pt14.pb4.mt4
                  div(v-for="(item, index) in menus"
                    :key="index"
                    @click="onList(item.title)").f.fm.mb12
                    v-icon(size="16px" :color="item.color").mr3 {{item.icon}}
                    span {{ item.title }}
              v-icon(@click="$router.push('/clients')" color="#fff").mr8 mdi-account-box-multiple
              v-icon(@click="$router.push('/trainers')" color="#fff").mr8 group
              //- v-icon(@click="$router.push('/inbox')" color="#fff").mr8 inbox
              //- v-icon(@click="$router.push('/calendar')" color="#fff").mr8 calendar_today
              //- v-icon(@click="$router.push('/settings')" color="#fff").mr8 settings
            div(@click="$router.push(`/trainers/${uid}`)").wrap-icon.f.fh
              img(:src="user.photoURL")
    ModalWindow(
      @closeModal="closeModalWindow"
      :modalContentId="modalWindowContentId"
      :showModal="showModalWindow")

</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-header {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background: $active_color;
  .header-content {
    position: relative;
    width: 93%;
    max-width: 820px;
    height: 100%;
    margin: 0 auto;
    .header-label {
      color: #fff;
      text-align: center;
    }
    .left {
      position: absolute;
      left: 0;
      height: 100%;
      .wrap-logo {
        img {
          height: 32px;
        }
        .logo-text {
          color: #fff;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .right {
      position: absolute;
      right: 0;
      height: 100%;
      .wrap-menu {
        .menu-content {
          background: $active_color;
          span {
            color: #fff;
          }
        }
      }
      .wrap-icon {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.menu-content {
  background: $active_color;
}
</style>

<script>
import Auth from '@/components/auth'
import { createNamespacedHelpers } from 'vuex'
import ModalWindow from '@/components/shared/Modal'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
// import { mdiCardAccountDetails } from '@mdi/js'

export default {
  components: {
    Auth,
    ModalWindow
  },
  data () {
    return {
      user: Object,
      menus: [
        { title: 'カルテ', icon: 'description', color: 'indigo' },
        { title: 'コンサルテーション', icon: 'hearing', color: 'green' },
        { title: '体組成計', icon: 'accessibility', color: 'orange' }
      ],
      showModalWindow: false,
      modalWindowContentId: 'selectClient',
      selectedRecordType: null
    }
  },
  computed: {
    ...mapStateAuth(['isLoggedIn', 'uid'])
  },
  methods: {
    ...mapActionsAuth(['getUserByUid']),
    async onLoggedIn () {
      if (!this.isAnonymous) {
        this.user = await this.getUserByUid(this.uid)
      }
    },
    async updateUserInfo () {
      this.user = await this.getUserByUid(this.uid)
    },
    openSelectClientWindow () {
      this.modalWindowContentId = 'selectClient'
      this.showModalWindow = true
    },
    closeModalWindow () {
      this.showModalWindow = false
    },
    onList (title) {
      switch (title) {
        case 'カルテ':
          this.selectedRecordType = 'karte'
          // this.$router.push(`/record/karte/new/${id}`)
          break
        case 'コンサルテーション':
          this.selectedRecordType = 'consultation'
          // this.$router.push(`/record/consultation/new/${id}`)
          break
        case '体組成計':
          this.selectedRecordType = 'inbody'
          // this.$router.push(`/record/inbody/new/${id}`)
          break
      }
      this.openSelectClientWindow()
    }
  }
}
</script>

<template lang="pug">
  div.wrap-module-trainer
    div.f.fm.flex-between.mb12.mt20
      h2 プロフィール
    div.trainer-content
      div(v-if="trainer").wrap-trainer.f.fm.flex-between.mt20.px20.py20
        div.trainer-info.f.fm
          div.f.fc.mr14
            div.wrap-img.f.fh
              img(:src="trainer.photoURL")
          div
            div.f.fm
              span.name.mr4 {{trainer.name}}
              // v-icon(size="22px" color="blue") edit
            span.email {{trainer.email}}
        div
          v-menu(offset-y)
            template(v-slot:activator="{ on }")
              v-icon(v-on="on") more_horiz
            v-list.px12.pt14.pb4.mt4
              div(
                v-for="(item, index) in menus"
                :key="index"
                @click="onList(item.label)").f.fm.mb12
                v-icon(size="16px").mr3 {{item.icon}}
                span.delete-text {{ item.label }}

        //- span(@click="logout").logout ログアウト

</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-module-trainer {
  width: 93%;
  max-width: 820px;
  margin: 0 auto;
  padding-top: 48px;
  h2 {
    color: $primary_text_color;
    font-size: 16px;
    border-bottom: $border_white;
  }
  .trainer-content {
    background: $primary_bg_color;
    border-radius: 3px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    .wrap-trainer {
      .trainer-info {
        border-radius: 3px;
        .wrap-img {
          width: 72px;
          height: 72px;
          border-radius: 50%;
          overflow: hidden;
          img {
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
          }
        }
        .name {
          display: block;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
        }
        .email {
          display: block;
          text-align: center;
        }
        .logout {
          display: block;
          text-align: center;
          color: red;
          font-size: 12px;
        }
      }
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  props: {
  },
  data () {
    return {
      trainer: null,
      menus: [
        { label: '編集', icon: 'edit' },
        { label: 'ログアウト', icon: 'launch' }
      ]
    }
  },
  async created () {
    this.trainer = await this.getUserByUid(this.$route.params.trainerId)
  },
  methods: {
    ...mapActionsAuth(['signOut', 'getUserByUid']),
    logout () {
      this.signOut()
      setTimeout(() => {
        this.$router.push('/sign-in')
      }, 400)
    },
    onFailedAuthentication () {
      this.$router.push('/sign-in')
    },
    onList (label) {
      switch (label) {
        case '編集':
          this.$emit('openSetTrainer')
          // mixpanel.track("onEdit", { createdAt: new Date() })
          break
        case 'ログアウト':
          this.logout()
          // mixpanel.track("onLogout", { createdAt: new Date() })
          break
      }
    },
    async updateTrainerInfo () {
      this.trainer = await this.getUserByUid(this.$route.params.trainerId)
      // mixpanel.track("updateTrainerInfo", { createdAt: new Date() })
    }
  }
}
</script>
